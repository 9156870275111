import axios from "axios";
const baseUrl = process.env.VUE_APP_ROOT_API+'/houses'

export async function getCadastral(params) {
    const response = await axios.post(baseUrl+'/info/cadastral', params)
    return response.data
}

export async function getAllHouses(params) {
    const response = await axios.get(baseUrl, {
        params: params
    })
    return response.data
}

export async function getHouseById(id) {
    const response = await axios.get(baseUrl + '/'+id+'/full')
    return response.data.data
}

export async function addHouse(params) {
    const response = await axios.post(baseUrl, params)
    return response.data.data
}

export async function updateHouse(id, params) {
    const response = await axios.put(baseUrl + '/'+id+'/full', params)
    return response.data.data
}

export async function updateHouseCount(id, params) {
    const response = await axios.put(baseUrl + '/'+id+'/counts', params)
    return response.data.data
}

export async function deleteHouse(id) {
    const response = await axios.delete(baseUrl+'/'+id)
    return response.data
}

export async function deleteHouseFromOsi(id, osiId) {
    const response = await axios.delete(baseUrl+'/'+id+'/osi/'+ osiId )
    return response.data
}

export async function getKzhkHouses() {
    const response = await axios.get(baseUrl + '/kzhk')
    return response.data.data
}

export async function getHouseTypes() {
    const response = await axios.get(baseUrl + '/types')
    return response.data
}

export async function getReport(params) {
    let items = []

    const response = await axios.get(baseUrl+'/report', {
        params: params,
        responseType: 'blob'
    })

    items = response.data
    return items
}